/* home.css */
@media (max-width: 768px) { /* Este es un breakpoint común para tablets */
    .home-title {
      margin-top: 30px; /* Agrega 20px de espacio en la parte superior */
    }
}

  @media (max-width: 640px) { /* Este es un breakpoint común para teléfonos móviles */
    .home-title {
      margin-top: 40px; /* Agrega 20px de espacio en la parte superior */
    }
    .home-subtitle {
      margin-top: 20px; /* Puedes ajustar si el subtítulo también necesita espacio */
    }
}

/* home.css */
@media (max-width: 640px) { /* Asumiendo 640px como breakpoint para teléfonos móviles */
    .shuffle-grid {
      height: 400px; /* 20px menos que la altura original */
    padding-bottom: 150px;
    }
}


