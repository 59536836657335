.footer {
    width: 100vw;
    height: 4rem;
    background: #183846;
    color: #eff5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 200;
    font-size:xx-small;
}