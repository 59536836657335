/* info.css */
@media (max-width: 768px) { /* Para tablets */
    .info-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* 2 columnas */
        justify-items: center; /* Centrar las columnas */
        grid-gap: 20px; /* Espacio entre las columnas */
    }
    .info-title {
        padding-top: 100px;
    }
    .info-title, .info-subtitle {
        text-align: center; /* Centrar el título y subtítulo */
    }

    .floating-phone-container {
        grid-column: span 2; /* El teléfono flotante ocupa las dos columnas */
    }
}

@media (max-width: 640px) { /* Para teléfonos móviles */
    .info-container {
        display: grid;
        grid-template-columns: 1fr; /* 1 columna */
        justify-items: center; /* Centrar los elementos horizontalmente */
        grid-gap: 20px; /* Espacio entre las filas */
    }
    .info-title, .info-subtitle {
        text-align: center; /* Centrar el título y subtítulo */
    }
    .info-subtitle{
        margin-left: 2rem;
    }
    .info-container > div {
        width: 100%; /* Establecer el ancho completo para los contenedores de las InfoCard */
        display: flex;
        flex-direction: column;
        align-items: center; /* Centrar las InfoCard verticalmente */
    }

    .info-card {
        width: 80%; /* Ajustar el ancho de las InfoCard */
        /* Considera ajustar otros estilos aquí si es necesario */
    }

    .floating-phone-container {
        grid-column: span 1; /* El teléfono flotante ocupa la columna única */
    }
}

