.mylogo {
    height: 3.5rem;
    width: auto;
    cursor: pointer;
    margin-left: 2rem;
}

.text-custom-color {
    color: #183846;
}

/* navbar.css */
@media (max-width: 768px) { /* Asumiendo 768px como breakpoint para tablets */
    .button-link button, .nav-button {
      font-size: 0.8rem; /* Reduce el tamaño de la fuente */
      padding: 0.5rem; /* Ajusta el padding */
    }
}

/* navbar.css */
@media (max-width: 768px) { /* Para móviles y tablets */
    .dropdown-menu {
    position: absolute;
    right: 90px; /* Ajusta según sea necesario */
    top: 60px; /* Ajusta según sea necesario */
    }
}
